<template>
  <div class="page-wrapper">
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant" />
        <img v-if="$route.meta.lang === 'fr'" src="@/assets/img/tagline-fr.svg" class="tagline" alt="Tagline Tremblant" />
        <img v-else-if="$route.meta.lang === 'en'" src="@/assets/img/tagline-en.svg" class="tagline" alt="Tagline Tremblant" />
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang"
            >FR</router-link
          >
        </div>
        <a :href="$t('site.smt-entrepot-link')" target="_blank" class="cta red">{{ $t('site.achetez-long') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone.svg" alt="Call Tremblant" /></a>
      <div class="mobile-cta">
        <a :href="$t('site.smt-entrepot-link')" target="_blank" class="cta red">{{ $t('site.achetez-long') }}</a>
      </div>
    </header>

    <section class="terms-wrap">
      <div v-if="$route.meta.lang === 'fr'">
        <button class="cta prune" @click.prevent="goback">Retour</button><br /><br />

        <h1 class="big-title">Modalités<br />Entrepôt de billets Tremblant</h1>

        <h2 class="small-title">Validité</h2>
        <ul>
          <li class="regular-text">
            Tous les billets achetés sur l’Entrepôt de billets Tremblant sont prépayés et valables uniquement pour la journée choisie.
          </li>
          <li class="regular-text">Les billets 2 jours, 3 jours, 4 jours et 5 jours doivent être consécutifs.</li>
          <li class="regular-text">Les quantités journalières sont limitées.</li>
          <li class="regular-text">Les prix peuvent varier.</li>
        </ul>

        <h2 class="small-title">Achat et réservation</h2>
        <ul>
          <li class="regular-text">
            Les billets peuvent être achetés sur l’Entrepôt de billets Tremblant en tout temps. Les quantités journalières sont limitées.
          </li>
        </ul>

        <h2 class="small-title">Livraison</h2>
        <ul>
          <li class="regular-text">
            Vous devez récupérer votre billet au Service à la clientèle, au Centre Aventure du Sommet des Neiges, à la billetterie (carte d'identité avec
            photo obligatoire) ou aux bornes d’impression la veille ou le jour même de votre arrivée.
          </li>
          <li class="regular-text">
            Si vous avez réservé ce produit avec votre hébergement sur le site, le billet vous sera livré directement à votre hôtel.
          </li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <ul>
          <li class="regular-text">
            Remboursable jusqu’à 72 heures avant la première journée d’utilisation prévue. Non remboursable après ce délai. Annulation ou modification à
            valeur inférieure (à la Station seulement): crédit sur carte cadeau montagne (applicable à l’achat de billets de ski, École sur neige,
            location, golf ou certaines activités), avec frais de 25 $ plus redevance et taxes. Modification à valeur égale ou supérieur sans frais en tout
            temps.
          </li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">Produit et rabais incessibles (ne peuvent être transférés d'une personne à une autre).</li>
        </ul>

        <h2 class="small-title">Passeport vaccinal et preuve de vaccination</h2>
        <p class="regular-text">
          Pour les détenteurs de billets (SkiMax, multi-jours, entrepôt, groupes, autres), la vérification se fera en personne à l’un de nos comptoirs de
          billetteries. Pour les
          <a href="https://www.tremblant.ca/planifiez/billets-passes/billets-de-ski?_id=E21F84CD553444D49B82840D3E7AE887&_z=z&sc_lang=fr" target="_blank"
            >billets multi-jours</a
          >, une seule validation sera nécessaire pour l’ensemble des journées de validité de votre billet.
        </p>
      </div>

      <div v-else-if="$route.meta.lang === 'en'">
        <button class="cta prune" @click.prevent="goback">Back</button><br /><br />

        <h1 class="big-title">Tremblant Ticket Outlet<br />Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">All tickets purchased through the Tremblant Ticket Outlet are prepaid and date specific.</li>
          <li class="regular-text">Daily quantities are limited.</li>
          <li class="regular-text">2-day, 3-day, 4-day and 5-day tickets must be used consecutively.</li>
          <li class="regular-text">Prices may vary.</li>
        </ul>

        <h2 class="small-title">Purchase and Booking</h2>
        <ul>
          <li class="regular-text">Tickets can be purchased on the Tremblant Ticket Outlet at all times. Daily quantities are limited.</li>
        </ul>

        <h2 class="small-title">Delivery</h2>
        <ul>
          <li class="regular-text">
            You can pick-up your ticket at our Guest Services Desk, the Adventure Center of the Sommet des Neiges hotel, ticket offices (photo ID required)
            or ticket printing kiosks the before or day of your arrival.
          </li>
          <li class="regular-text">If you purchased your ticket with onsite lodging 72h before your arrival, it will be delivered to your hotel.</li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <ul>
          <li class="regular-text">
            Can be refunded up to 72 hours prior to first planned day of use. Not refundable past this period. Cancellation or modification to a lesser
            value product (at the resort only): credit loaded on a mountain card (applicable on the purchase of lift tickets, Snow School, rental, golf or
            some activities), incurs a $25 fee plus royalty and taxes. Changes for an equal or greater value product do not incur fees.
          </li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">Non-transferable (product and discounts cannot be transferred from one person to the next).</li>
        </ul>

        <h2 class="small-title">Vaccine passport and proof of vaccination</h2>
        <p class="regular-text">
          Day ticket holders (Ski-max, multi-day, and others) will need to validate vaccination on-site. A single validation will be necessary for
          <a
            href="https://www.tremblant.ca/plan/tickets-and-passes/winter-lift-tickets?_id=E21F84CD553444D49B82840D3E7AE887&_z=z&sc_lang=en"
            target="_blank"
            >multi-day tickets</a
          >.
        </p>
      </div>
    </section>

    <footer-bar></footer-bar>

    <div class="logo-partenaire">
      <a v-if="$route.meta.lang === 'en'" href="https://www.laurentides.com/en" target="_blank"
        ><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"
      /></a>
    </div>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform" />
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              v-model="time1"
              name="arrivaldate"
              :lang="this.datepickerLang"
              :disabled-date="disabledBeforeToday"
              :input-attr="{ name: 'arrivaldate', required: 'required' }"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              v-model="time2"
              name="departuredate"
              :lang="this.datepickerLang"
              :disabled-date="disabledBeforeToday"
              :input-attr="{ name: 'departuredate', required: 'required' }"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input name="adultcount" type="number" value="2" min="1" required />
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input name="childCount" type="number" value="0" required />
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')" />
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: 'Éclaté de haut en bas',
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`,
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`,
        },
        // Og Image
        {
          p: 'og:image',
          c: '',
        },
        {
          name: 'twitter:image',
          content: '',
        },
      ],
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null,
    }
  },
  computed: mapState('app', ['appTitle']),
  mounted() {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
    window.addEventListener('scroll', () => {
      this.snowscroll()
    })
  },
  methods: {
    goback() {
      return this.$router.go(-1)
    },
    table1() {
      this.toggle = true
    },
    table2() {
      this.toggle = false
    },
    showlodgingform() {
      if (this.formlodging) {
        this.formlodging = false
      } else {
        this.formlodging = true
      }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
      if (this.sidepanel) {
        this.sidepanel = false
      } else {
        this.sidepanel = true
      }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${-150 + st / 2}px`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
